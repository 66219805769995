import PropTypes from 'prop-types';
import { Button as ButtonContainer } from 'dibs-elements/exports/Button';
import classnames from 'classnames';

export function Button(props) {
    const {
        isSecondary,
        fullWidth,
        onClick = () => {},
        text,
        disabled,
        size = 'large',
        className,
        children,
        href,
        dataTn,
    } = props;
    const classes = classnames({
        [className]: className,
        'w-100p': fullWidth,
    });

    const buttonType = isSecondary ? 'secondary' : 'primary';

    return (
        <ButtonContainer
            onClick={onClick}
            children={text || children}
            className={classes}
            size={size}
            disabled={disabled}
            href={href}
            dataTn={dataTn}
            type={buttonType}
        />
    );
}

Button.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    isSecondary: PropTypes.bool,
    children: PropTypes.node,
    dataTn: PropTypes.string,
};
