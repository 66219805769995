import {
    createContext,
    type FC,
    type ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { useIntl } from 'dibs-react-intl';

import { getFilterValue } from '../../finding/SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { getQueryParam } from '../../utils/uriUtils';

import { type SharedSaveSearchContext_itemSearch$key } from './__generated__/SharedSaveSearchContext_itemSearch.graphql';
import { type SharedSaveSearchContext_user$key } from './__generated__/SharedSaveSearchContext_user.graphql';
import { updateSaveSearchMutation } from '../../mutations/updateSaveSearchMutation';
import { getFollowTypeFromUri } from '../../utils/favoritesUtils';

const itemSearchFragment = graphql`
    fragment SharedSaveSearchContext_itemSearch on ItemSearchQueryConnection {
        pageType
        canFollowSearch
        meta {
            header
        }
        appliedFilters {
            name
            values {
                displayName
                urlLabel
                code
            }
        }
    }
`;

const userFragment = graphql`
    fragment SharedSaveSearchContext_user on User
    @argumentDefinitions(count: { type: "Int", defaultValue: 1 }, cursor: { type: "String" }) {
        serviceId
        favorites(
            first: $count
            after: $cursor
            types: $followSearchTypes
            pages: $followSearchPages
        )
            @connection(key: "SharedSaveSearchContext_favorites", filters: [])
            @include(if: $fetchUser) {
            edges {
                node {
                    __id
                    serviceId
                    displayName
                }
            }
        }
    }
`;

type UpdateSaveSearchProps = {
    userId: string;
    onComplete?: (isFollowing: boolean) => void;
};

type SharedSaveSearchContextType = {
    initAutoFollow: boolean;
    isCategorySearch: boolean;
    isFollowing: boolean;
    favoriteId: string | null;
    favoritesFetched: boolean;
    followSearchValue: string;
    showFollowButton: boolean;
    updateSaveSearch: (props: UpdateSaveSearchProps) => Promise<void>;
    userId: string | null;
};

export const SharedSaveSearchContext = createContext<SharedSaveSearchContextType>({
    initAutoFollow: false,
    isCategorySearch: false,
    isFollowing: false,
    favoriteId: null,
    favoritesFetched: false,
    followSearchValue: '',
    showFollowButton: false,
    updateSaveSearch: async () => {},
    userId: null,
});

export const useSharedSaveSearchContext = (): SharedSaveSearchContextType => {
    const context = useContext(SharedSaveSearchContext);
    if (!context) {
        throw new Error(
            'useSharedSaveSearchContext must be used within a SharedSaveSearchContextProvider'
        );
    }
    return context;
};

type Props = {
    children: ReactNode;
    itemSearch: SharedSaveSearchContext_itemSearch$key | null | undefined;
    user: SharedSaveSearchContext_user$key | null | undefined;
    searchTerm?: string;
    uriRef?: string | null;
};

export const SharedSaveSearchContextProvider: FC<Props> = ({
    children,
    itemSearch: itemSearchRef,
    user: userRef,
    searchTerm,
    uriRef,
}) => {
    const intl = useIntl();
    const [autoFollow, setAutoFollow] = useState(false);

    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);

    const { appliedFilters, canFollowSearch, pageType } = itemSearch || {};
    const environment = useRelayEnvironment();

    const favoritesFetched = !!user?.favorites;
    const favoriteId = user?.favorites?.edges?.[0]?.node?.serviceId || null;
    const favoriteRelayId = user?.favorites?.edges?.[0]?.node?.__id || '';
    const userId = user?.serviceId || null;

    // We need user information when SaveSearchButton updates to determine appropriate action
    useEffect(() => {
        const shouldAutoFollow = getQueryParam(document.location.search, 'follow') === 'search';
        setAutoFollow(shouldAutoFollow);
    }, []);

    let followSearchValue = searchTerm || itemSearch?.meta?.header || '';
    if (pageType === pageTypeConstants.MORE_FROM_SELLER) {
        const sellerName = getFilterValue(appliedFilters || [], 'sellerPk')?.displayName;
        followSearchValue = intl.formatMessage(
            {
                id: 'abf.custom.moreFromSeller.name.value',
                defaultMessage: 'All Items from {sellerName}',
            },
            { sellerName }
        );
    }

    const updateSaveSearch = useCallback(
        async ({ userId: _userId, onComplete = () => {} }: UpdateSaveSearchProps) => {
            try {
                //We don't need to refetch favorites, cause relay store will be updated using @connection key
                const response = await updateSaveSearchMutation(environment, {
                    connectionKey: 'SharedSaveSearchContext_favorites',
                    favoriteRelayId,
                    userId: _userId,
                    favoriteId,
                    page: uriRef || '',
                    name: followSearchValue,
                    type: getFollowTypeFromUri(uriRef || ''),
                });
                const isFollowing =
                    !!response?.favoritePage?.user?.savedSearches?.edges?.[0]?.node?.serviceId;
                onComplete(isFollowing);
            } catch {
                // do nothing
            }
        },
        [environment, favoriteId, favoriteRelayId, followSearchValue, uriRef]
    );
    const value = useMemo(
        () => ({
            initAutoFollow: autoFollow,
            showFollowButton: !!canFollowSearch,
            isCategorySearch: !searchTerm,
            isFollowing: !!favoriteId,
            favoriteId,
            favoritesFetched: favoritesFetched,
            followSearchValue,
            updateSaveSearch,
            userId,
        }),
        [
            autoFollow,
            canFollowSearch,
            favoriteId,
            favoritesFetched,
            followSearchValue,
            searchTerm,
            updateSaveSearch,
            userId,
        ]
    );
    return (
        <SharedSaveSearchContext.Provider value={value}>
            {children}
        </SharedSaveSearchContext.Provider>
    );
};
